import { useSelector } from 'react-redux'
import { isAccountVerified } from '../../../selectors'
import { useUtilities } from '../../useUtilities'

const useProductPrice = ({ salePrice, listPrice, type = 'product' }) => {
  const { isAuthenticated, isString } = useUtilities()
  if (isString(salePrice)) salePrice = salePrice.trim().length > 0 ? parseInt(salePrice) : 0
  if (isString(listPrice)) listPrice = listPrice.trim().length > 0 ? parseInt(listPrice) : 0
  let showSalePrice = true
  let showListPrice = true
  let showMissingPrice = false
  const isAuthed = isAuthenticated()
  const isVerified = useSelector(isAccountVerified)
  /*   
  Unauthenticated Rules
    - Only look at list price for pricing display
    - Dont show a Zero list price
    - if we know list and sale price are both Zero dont let the user add it to the cart

            list  sale
   Case 1:    0     0
   Case 2:    0     5
   Case 3:    5     5
   Case 4:    5     0
   Case 5:    10    5
   Case 6:    5     10
   */
  if (!isAuthed || !isVerified) {
    showSalePrice = false // Case 1-5
    if (listPrice <= 0) {
      // Case 1
      showMissingPrice = true
      showListPrice = false
    }
  }

  /*   
  Authenticated Rules
    - Dont show a Sale price for of list and sale are Zero
    - if we know list and sale price are both Zero dont let the user add it to the cart

            list  sale
   Case 7:    0     0
   Case 8:    0     5
   Case 9:    5     5
   Case 10:   5     0
   Case 11:   10    5
   Case 12:   5     10
   */
  if (isAuthed && isVerified) {
    showListPrice = false
    if (salePrice <= 0 && listPrice <= 0) {
      // Case 7
      showMissingPrice = true
      showSalePrice = false
    }

    if (salePrice <= listPrice) showListPrice = true // Case 11
  }
  const showAddToCart = !(salePrice <= 0 && listPrice <= 0)

  return { showAddToCart, showSalePrice, showListPrice, showMissingPrice }
}
export { useProductPrice }
